import React from 'react';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Header.css";

const Header = () => {
  const headerStyle = {
    textAlign: 'center',
    color: 'white',
    padding: '60px 20px',
    marginBottom: '50px',
    maxWidth: '1300px',
    margin: '0 auto',
    marginTop: '70px',
    lineHeight: '25px',
    wordSpacing: '2px',
  };

  const textStyle = {
    textAlign: 'justify',
    color: 'white',
    fontSize: '18px',
    maxWidth: '800px',
    margin: '0 auto',
    marginBottom: '10px', // Reduced margin-bottom
  };

  return (
    <div className="container mt-5">
      <h1 style={headerStyle}>What is hackX?</h1>
      <div className="row d-flex align-items-center">
        <div className="col-12 col-lg-6 order-2 order-lg-1 leftC">
          <p style={textStyle}>
            hackX is a renowned inter-university startup challenge organized by the Industrial Management Science Students' Association (IMSSA) of the Department of Industrial Management, University of Kelaniya. Approaching its ninth edition in 2024, the event will center on the theme "Sustainable Innovation," eagerly anticipating groundbreaking ideas that will shape the future of Sri Lankan startups.
            <br /><br />
            hackX 9.0 aims to provide undergraduates with a premier platform to present their ideas to a panel of esteemed industry experts. This event offers the opportunity to build valuable connections and gain support from investors to bring their innovative ideas to life.
          </p>
        </div>
        <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center mb-4 mb-lg-0 rightC">
          <motion.div
            animate={{ rotate: [0, 10, -10, 0], transition: { duration: 4, repeat: Infinity, repeatDelay: 2 } }}
            style={{ display: 'flex', justifyContent: 'center', originX: 0.5, originY: 1, marginTop: '10px' }} // Reduced margin-top
          >
            <img src="mascot.webp" alt="Mascot" style={{ maxWidth: '100%' }} />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Header;
